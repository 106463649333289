<template>
  <table
    style="font-size: 0; margin-top: 10px;"
    cellspacing="0"
    cellpadding="0"
    border="0"
    role="presentation"
  >
    <tbody>
      <tr>
        <td>
          <span style="font-size: 10px; color: #888; border-top: 1px solid #eee; padding-top: 5px;">Email signature by <a href="https://salessimplify.com?">salessimplify.com</a></span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'PromoteSignature',

  data () {
    return {

    }
  }
}
</script>

<style lang="scss">

</style>
